import React from "react";
import { FaPlug } from "react-icons/fa"; // Example: FontAwesome Icon

const SiteMapRight = () => {
  return (
    <div className="sideRight">
      <ul>
        <li>
          <a
            href="https://iwebapps.noidapower.com:8032/Online_applications.aspx/?appName=NSc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaPlug className="icon" /> {/* Add an icon */}
            <span>Apply For New Connection</span> {/* Full text */}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SiteMapRight;