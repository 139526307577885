import React from "react";
import { Link } from "react-router-dom";
import FixMenu from "../components/FixMenu";
import Header2 from "../components/Header2";
import Slider from "react-slick";
import { Margin } from "@syncfusion/ej2-react-charts";

const ConsumerPortal = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    cssEase: "linear",
  };
  function menuclaseFuncation() {
    var element = document.getElementById("fixmnu");
    element.classList.remove("active");

    var element = document.getElementById("bodyaddid");
    element.classList.remove("scrollstop");
  }
  return (
    <>
      <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods mb0px">
          <div className="container-fluid">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-3 imphdmdev consumer">
                    <div className="inner_boxs">
                      <div className="our_other_links navhdnsd">
                        <ul className="nav nav-tabs nav_des_con">
                          <li className="nav-item">
                            <a
                              href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx"
                              className="nav-link active"  rel="noreferrer"
                              target="_blank"
                            >
                              <img src="assets/images/meter-reading.png" /> Self
                              Meter Reading
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="https://iwebapps.noidapower.com:8032/quick_pay.aspx"  rel="noreferrer"
                              className="nav-link "
                              target="_blank"
                            >
                              <img src="assets/images/bill-payment.png" /> Quick
                              Bill Payment
                            </a>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/cash-collection-centres"
                              className="nav-link"
                            >
                              {" "}
                              <img src="assets/images/payment-option.png" />{" "}
                              Payment Options
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/CGRFs-Ombudsman" className="nav-link" style={{fontSize:"15px"}}>
                              {" "}
                              <img src="assets/images/legal.png" /> CGRFs & Ombudsman
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-9 bg_cols consumer">
                    <div className="form_div">
                      <div
                        id="demo"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        {/* <ul className="carousel-indicators">
                          <li
                            data-target="#demo"
                            data-slide-to="0"
                            className="active"
                          ></li>
                          <li data-target="#demo" data-slide-to="1"></li>
                          <li data-target="#demo" data-slide-to="2"></li>
                        </ul> */}
                        <Slider {...settings2} className="slidee">
                          <div className="carousel-item">
                            <img
                              src="assets/images/banner1.jpg"
                              style={{ height: "300px" }}
                              alt=""
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              src="assets/images/banner2.jpg"
                              style={{ height: "300px" }}
                              alt=""
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              src="assets/images/banner3.jpg"
                              style={{ height: "300px" }}
                              alt=""
                            />
                          </div>
                        </Slider>

                        {/* <Link
                          className="carousel-control-prev"
                          to="#demo"
                          data-slide="prev"
                        >
                          <span className="carousel-control-prev-icon"></span>
                        </Link>
                        <Link
                          className="carousel-control-next"
                          to="#demo"
                          data-slide="next"
                        >
                          <span className="carousel-control-next-icon"></span>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="main_sectiods" style={{ paddingBottom: "0px" }}>
          <div className="container-fluid">
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 bg_cols">
                    <div className="form_div">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="col-xs-12 newconst">
                          <div className="row">
                            <div className="col-sm-12">
                              <ul className="box_desmain">
                                <li>
                                  <img
                                    src="assets/img/small/contact-for-power-procurement.jpg"
                                    alt=""
                                  />{" "}
                                  <Link to="/online-applications">
                                    Application Form{" "}
                                  </Link>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/payment-options.jpg"
                                    alt=""
                                  />{" "}
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/complaint_new.aspx"
                                    target="_blank"
                                  >
                                    {" "}
                                    Register a complaint
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <img
                                    src="assets/img/small/quick-bill-payment.jpg"
                                    alt=""
                                  />
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/quick_pay.aspx"
                                    target="_blank"
                                  >
                                    {" "}
                                    Bill / Estimate Payments
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <img
                                    src="assets/img/small/outage-notification.jpg"
                                    alt=""
                                  />
                                  <Link to="/digital-services">
                                    Digital Services{" "}
                                  </Link>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/report-a-concern.jpg"
                                    alt=""
                                  />{" "}
                                  <Link to="/knowledge-center">
                                    Knowledge Center{" "}
                                  </Link>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/qms-real-time.jpg"
                                    alt=""
                                  />{" "}
                                  <Link to="/consumer-engagement-and-awareness">
                                    {" "}
                                    Consumer engagement
                                    <br /> and awareness
                                  </Link>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/vendor-registration.jpg"
                                    alt=""
                                  />{" "}
                                  <Link to="/feedback"> Feedback</Link>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/quick-e-bill-download.jpg"
                                    alt=""
                                  />{" "}
                                  <Link to="/information-board">
                                    {" "}
                                    Information Board
                                  </Link>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/quick-e-bill-download.jpg"
                                    alt=""
                                  />{" "}
                                  <Link to="/consumerOffer">
                                    {" "}
                                    Consumer Offer
                                  </Link>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/preapidMEter.jpg"
                                    alt=""
                                  />{" "}
                                  <Link to="/ConsumerOwnChoiceMeter">
                                    {" "}
                                    Consumer Own Choice meter
                                  </Link>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/vendor-registration.jpg"
                                    alt=""
                                  />{" "}
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/LMV5BenefitScheme.aspx"
                                    target="_blank"
                                  >
                                    {" "}
                                    Tubewell(LMV-5) Subsidy Scheme
                                  </a>{" "}
                                </li>
                                <li>
                                  <img
                                    src="assets/img/small/report-a-concern.jpg"
                                    alt=""
                                  />{" "}
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/Authenticate/login.aspx"
                                    target="_blank"
                                  >
                                    {" "}
                                    Single Point Compliance Reports
                                  </a>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="second_sec">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="top_title">
                  You May Also be <span>interested in</span>
                </div>
              </div>
            </div>
            <div className="row justify-content-center padtops">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form_div">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="col-xs-12 newconst">
                          <div className="row">
                            <div className="col-sm-12">
                              <ul className="box_desmain2">
                                <li>
                                  <Link to="/quick-links">
                                    <img
                                      src="assets/img/small/quick-links.jpg"
                                      alt=""
                                    />
                                    Quick Links{" "}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/safety-first">
                                    <img
                                      src="assets/img/small/contact-for-power-procurement.jpg"
                                      alt=""
                                    />
                                    Safety First
                                  </Link>{" "}
                                </li>
                                <li>
                                  <Link to="/grievence">
                                    <img
                                      src="assets/img/small/grievance-redressal.jpg"
                                      alt=""
                                    />
                                    Grievance Redressal Mechanism
                                  </Link>{" "}
                                </li>
                                <li>
                                  <a
                                    href="https://iwebapps.noidapower.com:8032/NPCL_creatives.aspx"
                                    target="_blank"
                                  >
                                    <img
                                      src="assets/img/small/video-photo-gallery.jpg"
                                      alt=""
                                    />
                                    Video / Photo Gallery
                                  </a>
                                </li>
                                <li>
                                  <Link to="/contactOverPhone">
                                    <img
                                      src="assets/img/small/contact.jpg"
                                      alt=""
                                    />
                                    Contact Us
                                  </Link>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ConsumerPortal;
