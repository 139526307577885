import React, { useEffect } from "react";
import ReactCarousel from "../components/ReactCarousel";
import ReactCarousel2 from "../components/ReactCarousel2";
import CompProfile from "../components/CompProfile";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import FixMenu from "../components/FixMenu";
import Modal from "../components/Modal";
import Announcement from "../components/Announcement";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="bodyaddid">
        <Header />
        <FixMenu />
        <section className="main-banner homepagebner">
          <div className="container-fluid">
            <div className="row">
              <video className="mainslider" autoPlay muted loop={true}>
                <source src="assets/video/final-pr-web.mp4" type="video/mp4" />
                <source src="final-pr-web.ogg" type="video/ogg" />
              </video>
            </div>
          </div>
          <div className="topiconlist">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-sm-12 npdnfd">
                  <div className="icon1">
                    <ul>
                      <li>
                        <Link to="/pre-paid-meter-recharge">
                          <img src="assets/img/icon/1.png" alt="Pre Paid Meter Recharge" />
                          <h6>
                            Pre-Paid Meter <br />
                            Recharge
                          </h6>
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          <img src="assets/img/icon/2.png" alt="Self Meter Reading" />
                          <h6>
                            Self Meter <br />
                            Reading
                          </h6>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/Ebill_download.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          <img src="assets/img/icon/3.png" alt="E-Bill Download" />
                          <h6>
                            Quick E-Bill <br />
                            Download
                          </h6>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/quick_pay.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          <img src="assets/img/icon/4.png" alt="Quick Bill Payment" />
                          <h6>
                            Quick Bill <br />
                            Payment
                          </h6>
                        </a>
                      </li>
                      <li>
                        <Link to="/online-applications">
                          <img src="assets/img/icon/5.png" alt="Online application" />
                          <h6>
                            Online <br />
                            E-application
                          </h6>
                        </Link>
                      </li>
                      <li>
                        <Link to="/cash-collection-centres">
                          <img src="assets/img/icon/6.png" alt="Cash collection center" />
                          <h6>
                            Payment <br />
                            Options
                          </h6>
                        </Link>
                      </li>
                      <li>
                        <Link to="/online-vendor-registration">
                          <img src="assets/img/icon/7.png" alt="Vendor Registration" />
                          <h6>
                            Vendor <br />
                            Registration
                          </h6>
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/load_outage_schedule.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          <img src="assets/img/icon/8.png" alt="Outage Notification" />
                          <h6>Outage Notification</h6>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://iwebapps.noidapower.com:8032/reporting_theft.aspx"
                          target="_blank" rel="noreferrer"
                        >
                          <img src="assets/img/icon/9.png" alt="Report Concern" />
                          <h6>
                            Report a <br />
                            Concern
                          </h6>
                        </a>
                      </li>
                      <li>
                        <Link to="/callcenter">
                          <img src="assets/img/icon/10.png" alt="Call center" />
                          <h6>
                            Contact <br />
                            Us
                          </h6>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Announcement tab start from here */}
              <div className="row justify-content-center">
              {/* <Announcement /> */}
                 <div className="col-sm-6 p-0">
                  <div className="anadd">
                    <div className="row justify-content-end p-0 m-0">
                      <div className="col-sm-3 p-0">
                        <h4>ANNOUNCEMENT</h4>
                      </div>
                      <div className="col-sm-9 p-0">
                        <div className="flex5258wrap fleright1">
                        <marquee>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/P2P_Energy_Trading_using_Blockchain_Pilot-Addendum-2.pdf" target="_blank" rel="noreferrer"> Inviting Bids for Pilot of “P2P Energy trading using Blockchain”- Pre-bid Query Addendum-2 </a> 
                        </div>
                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/RFP_for_Virtual_Reality_Lab_Setup.pdf" target="_blank" rel="noreferrer"> RFP for the Implementation of Virtual Reality Lab Setup at NPCL </a> 
                        </div>
                        
                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/SPtoMC_for_MultiStoried_Building_New.aspx" target="_blank" rel="noreferrer">Important Notice for Owners / Allottees of Multi-Storied Buildings in Greater Noida published on TOI &amp; NBT dated 14th March 2021 </a> 
                        </div>
                        
                         <div className="sliderannoucement">
                         <a href="https://iwebapps.noidapower.com:8032/download/UPERC.pdf" target="_blank" rel="noreferrer"> UPERC Preventive Guidelines for Corona Virus </a> 
                        </div> 
                        
                        <div className="sliderannoucement">

                        <a href="https://iwebapps.noidapower.com:8032/download/Banking_tender_for_01.11.2022_to_30.09.2023.pdf" target="_blank" rel="noreferrer">Tender for banking of power for 01.11.2022 to 30.09.2023 on Short Term Basis</a> 
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/Public Notice on website_Oxigen.pdf" target="_blank" rel="noreferrer">Public Notice for Termination of CMS agreement with Oxigen</a>
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/13thAmendmentElectricitysupplycode2005-pdf810201862458PM.pdf" target="_blank" rel="noreferrer"> UP Electricity Supply Code � 13th Amendment 2018</a> 
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/Public Notice (Mar 25 2018).pdf" target="_blank" rel="noreferrer">Public Notice for Termination of CMS agreement with Suvidhaa</a> 
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download_mob_app.aspx">Download Mobile App.</a> 
                        </div>
                        
                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/download/suo_moto_Order_of_UPERC_dated_16_09_2014_regarding_change_in_rates_of_Meter_&amp;_Meter_Box.pdf" target="_blank" rel="noreferrer">Amendment in rates of meters by order of The Hon'ble UPERC dated 16.09.2014.</a>
                        </div>

                        <div className="sliderannoucement">
                        <a href="https://iwebapps.noidapower.com:8032/Authenticate/login.aspx" target="_blank" rel="noreferrer">Register for SMS Alerts/Services.</a> 
                        </div>
                      </marquee>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 p-0">
                  <div className="flex5258wrap">
                    <marquee>

                    <a href="https://iwebapps.noidapower.com:8032/Authenticate/login.aspx" target="_blank" rel="noreferrer" className="highLigh">	Upload compliance report for Single Point Society</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <a href="https://www.noidapower.com/tenders" target="_blank" rel="noreferrer" className="highLigh">	Notice Inviting Tenders for Request for Selection (RfS) for Long Term Procurement of 300 MW Wind Solar Hybrid Power from Grid Connected Projects Under Tariff Based Competitive Bidding, RfS No: NPCL/LT/Hybrid/24-25 dated 08-November-2024</a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <a href="https://www.noidapower.com/NPCL-Tariff-Petition-ARR-2024-25" target="_blank" rel="noreferrer" className="highLigh"> NPCL Tariff Petition for True-up for FY 2022-23, APR for FY 2023-24 and ARR & Tariff for FY 2024-25  </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    
                    <a href="https://iwebapps.noidapower.com:8032/download/PublicHearingNoticeFY2024.pdf" target="_blank" rel="noreferrer" className="highLigh">UPERC is conducting a Public Hearing on the Tariff Petition of NPCL for FY 2024-25 on 19th July 2024 from 11 AM onwards at Auditorium 1, GBU, Greater Noida.</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 


                    NPCL bill payments now available at Digital Payment Centres near you. The service is available free of cost. 
                        <a href="https://iwebapps.noidapower.com:8032/DigitalPayment_Centre.aspx" target="_blank" rel="noreferrer" className="highLigh"> Click here  </a>  to know more.  
                        
                          
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           
                        Consumers can log complaint &amp; avail information by sending SMS to 7840002288 from their registered Mobile No. Click   <a href="https://tinyurl.com/yc9sjqfj" target="_blank" rel="noreferrer">
                         here
                          </a> to view the SMS short codes and their purpose.
                     
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||||||||||||||||||||||||||||||||||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                       
                  
                        Single Phase consumers can now submit Self Meter Reading with photo proof between 1st to 10th Day of every month,<a href="https://iwebapps.noidapower.com:8032/self_meter_reading.aspx" target="_blank" rel="noreferrer">
                        click here
                          </a>
                    </marquee>
                  </div>
                </div> 
              </div>

              {/* Announcement tab end  here */}

            </div>
          </div>
        </section>

        <section className="twoitem pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="twoitemtext">
                      <h2> Green Energy </h2>
                      <p>
                      NPCL in its endeavour towards sustainable journey of promoting the Green Initiative and encouraging the culture of environment friendly practices and take immense pleasure to invite you to partner in the journey of climate change through reduction of Carbon footprints.
                      </p>
                      <a href="https://iwebapps.noidapower.com:8032/Green_Energy_policy.aspx" className="readmore11">
                         Join Green Energy
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-6 p-0">
                    <div className="twoitemimg">
                      <img src="assets/img/Green-energyimage.jpg" alt='Green Energy' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="twoitem pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10">
                <div className="row">
                  <div className="col-sm-6 p-0">
                    <div className="twoitemimg">
                      <img src="assets/img/about1.png" alt="Values" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="twoitemtext">
                      <h2>Values & Beliefs</h2>
                      <p>
                        The NPCL motto is ‘Learning is a never-ending process’.
                        The Company believes in keeping up and evolving with the
                        changing times. It highly values the principles of
                        trust, respect, and collaborative practices.
                      </p>
                      <Link to="/values-beliefs" className="readmore11">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="twoitem twoitem2 pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="twoitemtext">
                      <h2>Technology Initiatives</h2>
                      <p>
                        Innovation and imagination are the most effective tools
                        of NPCL to achieve excellence and success in the present
                        competitive scenario.
                      </p>
                      <a href="./initiativeundertaken" className="readmore11">
                        Read More
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-6 p-0">
                    <div className="twoitemimg">
                      <img src="assets/img/about2.png" alt="NPCL" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="threeitem">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="boxitem">
                      <div className="boimg">
                        <img src="assets/img/three1.png" alt="Green Energy" />
                      </div>
                      <div className="threetext">
                      <div className="threetext1">
                          <h4>Payment Options</h4>
                          <p>
                            NPCL gives you a number of options to make your
                            payment with ease. Please click below to view the
                            different modes of payment.
                          </p>
                          <a href="./cash-collection-centres">Read More</a>
                     </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="boxitem">
                      <div className="boimg">
                        <img src="assets/img/three2.png" alt="Lower Bill" />
                      </div>
                      <div className="threetext">
                        <div className="threetext1">
                          <h4>Tips to lower your bill</h4>
                          <p>
                            Small steps can make a big difference. Follow these
                            tips to lower your electricity bill.
                          </p>
                          <a href="./easy-ways-to-lower-your-bill">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="boxitem">
                      <div className="boimg">
                        <img src="assets/img/three3.png"  alt="Safety Tips"/>
                      </div>
                      <div className="threetext">
                        <div className="threetext1">
                          <h4>Safety Tips</h4>
                          <p>
                            Always keep yourself and your loved ones safe by
                            keeping a few safety tips in mind at all times.
                          </p>
                          <a href="./safety">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="socialfeed">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <h3>SOCIAL FEED</h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-10">
                {/* <div className="w-100 text-center">
                  <button
                    className="btn btn-default filter-button"
                    data-filter="all"
                  >
                    All
                  </button>
                  <button
                    className="btn btn-default filter-button"
                    data-filter="hdpe"
                  >
                    Facebook
                  </button>
                  <button
                    className="btn btn-default filter-button"
                    data-filter="sprinkle"
                  >
                    Instagram
                  </button>
                  <button
                    className="btn btn-default filter-button"
                    data-filter="spray"
                  >
                    Twitter
                  </button>
                  <button
                    className="btn btn-default filter-button"
                    data-filter="irrigation"
                  >
                    YouTube
                  </button>
                </div> */}
              </div>

              <ReactCarousel />
              <div className="col-sm-12">
                <ul className="follow_us_icon">
                  <li className="followuson">Follow us on -</li>
                  {/* <li>
                    <a href="#" target="_blank">
                      <img src="assets/img/facebook.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <img src="assets/img/twitter.png" alt="" />
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCtR4-FW_xyalLl8nPRBfxMw?view_as=subscriber"
                      target="_blank"
                    >
                      <img src="assets/img/youtube.png" alt="" />
                    </a>
                  </li>
                  {/* <li>
                    <a href="#" target="_blank">
                      <img src="assets/img/instragram.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <img src="assets/img/linkedin.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <img src="assets/img/pinterest.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <img src="assets/img/telegram.png" alt="" />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <ReactCarousel2 />
        <CompProfile />
        <Modal />
      </div>
    </>
  );
};

export default Home;
