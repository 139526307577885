import React from "react";
import { Link } from "react-router-dom";

const SiteMap = () => {
  return (
    <React.Fragment>
      {/* <div className="sideleft">
        <ul>
        <li>
        <a
            href="https://appsqa:7051/Online_applications.aspx/?appName=NSc"
            target="_blank"
            rel="noopener noreferrer"
          >
              <img src="assets/img/icon/icon2.png" /> New Connection
              </a>
          </li>
        </ul>
      </div> */}
      <div className="sideleft">
        <ul>
        <li>
        <a
            href="https://iwebapps.noidapower.com:8032/Online_applications.aspx/?appName=NSc"
            target="_blank"
            rel="noopener noreferrer"
          >
              <img src="assets/img/icon/icon1.png" /> New Connection
              </a>
          </li>
          <li>
            <Link to="/sitemap2" target="_blank">
              <i className="fa fa-sitemap"></i> Sitemap
            </Link>
          </li>
          <li>
            <Link to="/faq" target="_blank">
              <i className="fa fa-question-circle"></i> FAQ’S
            </Link>
          </li>
          <li>
            <Link to="/quick-links" target="_blank">
              <i className="fa fa-download"></i> Quick Links
            </Link>
          </li>

          {/* <li>
            <Link to="/Sustainability" target="_blank" className="susTab">
            <i className="fa fa-leaf"></i>  Sustainability
            </Link>
          </li> */}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SiteMap;
