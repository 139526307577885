import React from "react";
import Header2 from "../components/Header2";
import FixMenu from "../components/FixMenu";
import { Link } from "react-router-dom";
import { useState } from "react";
import Table from "../components/Table";

const DocumentChecklist = () => {
  const [show, setShow] = useState(false);
  const [showName, setShowName] = useState(false);
  const [showCat, setShowCat] = useState(false);
  const [showPer, setShowPer] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showNot, setShowNot] = useState(false);
  const [showService, setShowService] = useState(false);
  const [showND, setShowND] = useState(false);
  const [loadReduction, setLoadReduction] = useState(true);
  const [augmentation, setAugmentation] = useState(false);

  const openLoad = () => {
    setLoadReduction(true);
    setAugmentation(false);
  };
  const openAug = () => {
    setLoadReduction(false);
    setAugmentation(true);
  };

  const onChangeOption = (e) => {
    if (e.target.value === "LIST1") {
      setShowCat(false);
      setShow(true);
      setShowName(false);
      setShowPer(false);
      setShowLoad(false);
      setShowAddress(false);
      setShowNot(false);
      setShowService(false);
      setShowND(false);
    }
    if (e.target.value === "LIST2") {
      setShowCat(false);
      setShowName(true);
      setShow(false);
      setShowPer(false);
      setShowAddress(false);
      setShowLoad(false);
      setShowNot(false);
      setShowService(false);
      setShowND(false);
    }
    if (e.target.value === "LIST3") {
      setShowCat(true);
      setShowPer(false);
      setShowName(false);
      setShow(false);
      setShowLoad(false);
      setShowAddress(false);
      setShowNot(false);
      setShowService(false);
      setShowND(false);
    }
    if (e.target.value === "LIST4") {
      setShowLoad(false);
      setShowPer(true);
      setShowCat(false);
      setShowName(false);
      setShow(false);
      setShowAddress(false);
      setShowNot(false);
      setShowService(false);
      setShowND(false);
    }
    if (e.target.value === "LIST5") {
      setShowLoad(true);
      setShowPer(false);
      setShowCat(false);
      setShowName(false);
      setShow(false);
      setShowAddress(false);
      setShowNot(false);
      setShowService(false);
      setShowND(false);
    }
    if (e.target.value === "LIST6") {
      setShowAddress(true);
      setShowLoad(false);
      setShowPer(false);
      setShowCat(false);
      setShowName(false);
      setShow(false);
      setShowNot(false);
      setShowService(false);
      setShowND(false);
    }
    if (e.target.value === "LIST7") {
      setShowNot(true);
      setShowAddress(false);
      setShowLoad(false);
      setShowPer(false);
      setShowCat(false);
      setShowName(false);
      setShow(false);
      setShowService(false);
      setShowND(false);
    }
    if (e.target.value === "LIST8") {
      setShowService(true);
      setShowNot(false);
      setShowAddress(false);
      setShowLoad(false);
      setShowPer(false);
      setShowCat(false);
      setShowName(false);
      setShow(false);
      setShowND(false);
    }
    if (e.target.value === "LIST9") {
      setShowService(false);
      setShowNot(false);
      setShowAddress(false);
      setShowLoad(false);
      setShowPer(false);
      setShowCat(false);
      setShowName(false);
      setShow(false);
      setShowND(true);
    }
  };
  return (
    <>
      <div id="bodyaddid">
        <Header2 />
        <FixMenu />
        <section className="main_sectiods consumersec">
          <div className="upperdesignds">
            <div className="row">
              <div className="col-md-1">
                <img
                  className="bgstlsd"
                  src="assets/img/small/payment-options.jpg"
                  alt=""
                />
              </div>
              <div className="col-md-11 textdesing">
                <h2 className="headtit">
                  {loadReduction ? "Document Checklist" : "Document Checklist"}
                </h2>
              </div>
            </div>
          </div>
          <div className="container">
            <section className="main_sectiods">
              <div className="container">
                <div className="row justify-content-center padtops">
                  <div className="col-sm-12">
                    <div className="col-lg-12 brdcum_designs">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="#">Home</Link>
                          <span className="icondsds">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/consumer-portal" target="_blank">
                            Consumer Portal
                          </Link>
                          <span className="icondsds">
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#" className="active">
                            Document Checklist
                          </a>
                        </li>
                      </ol>
                    </div>

                    {loadReduction ? (
                      <div className="col-sm-12 bg_cols">
                        <div className="form_div">
                          <div className="tab-content tab_main">
                            <div
                              className="tab-pane fade show active"
                              id="newconnection"
                            >
                              <div
                                className="tab-pane"
                                id="checklist"
                                style={{ marginLeft: "30px" }}
                              >
                                <h4 className="mt-2"> Document Checklist </h4>
                                <div className="row">
                                  <form action="">
                                    <div className="col-md-4">
                                      <label for="country">
                                        Select Checklist <span>*</span>
                                      </label>
                                      <select
                                        name="country"
                                        id="country"
                                        className="slt_check"
                                        onClick={(e) => onChangeOption(e)}
                                      >
                                        <option disabled selected="selected">
                                          Select
                                        </option>
                                        <option value="LIST1">
                                          New Connection Domestic
                                        </option>
                                        <option value="LIST9">
                                          New Connection Non Domestic
                                        </option>
                                        <option value="LIST2">
                                          Name Mutation
                                        </option>
                                        <option value="LIST3">
                                          Categogy Change
                                        </option>
                                        <option value="LIST4">
                                          Permanent Disconnection & Security
                                          Refund
                                        </option>
                                        <option value="LIST5">
                                          Load Reduction/Load Augmentation
                                        </option>
                                        <option value="LIST6">
                                          Name & Address Correction
                                        </option>
                                        <option value="LIST7">
                                          Not in Use Declaration
                                        </option>
                                        <option value="LIST8">
                                          Shifting of Service / Meter
                                        </option>
                                      </select>
                                    </div>

                                    {show ? (
                                      <div className="list1">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/newConn_Domestic.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/NSC Dom Eng Hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showName ? (
                                      <div className="list2">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Name-Mutation.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/NTR Eng HN.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showCat ? (
                                      <div class="list3">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Category-Change.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/Category Hn  Eng.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showPer ? (
                                      <div className="list4">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Permanent-Disconnection-and-Security-Refund.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/PD SD NOC eng hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showLoad ? (
                                      <div className="list5">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Load-reduction.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/LALR engHn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showAddress ? (
                                      <div className="list6">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Name-and-address-correction.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/NAC Eng Hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showNot ? (
                                      <div className="list7">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/NotInUseDeclaration.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/Not in use Eng Hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showService ? (
                                      <div className="list8">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Shifting_Of_Services.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/SFT eng Hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    
                                    {showND ? (
                                      <div className="list9">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/newConn_NON-Domestic.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/NSC Non Domestic Application-merged.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {augmentation ? (
                      <div className="col-sm-12 bg_cols">
                        <div className="form_div">
                          <div className="tab-content tab_main">
                            <div
                              className="tab-pane fade show active"
                              id="newconnection"
                            >
                              <div className="bs-example bs-example-1">
                                <div
                                  className="tab-pane fade show active"
                                  id="newconnection"
                                >
                                  <ul
                                    className="nav nav-tabs nav_tbds"
                                    style={{ overflow: "hidden" }}
                                  >
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Calculate Your Load
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-doc"
                                        className="nav-link active"
                                        data-toggle="tab"
                                      >
                                        Document Checklist
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-sample"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Sample Documents
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-faq"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        FAQs
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-apply"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Apply
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-status"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Application Status
                                      </Link>
                                    </li>
                                    <li className="nav-item">
                                      <Link
                                        to="/load-reduction-augmentation-feedback"
                                        className="nav-link"
                                        data-toggle="tab"
                                      >
                                        Feedback
                                      </Link>
                                    </li>
                                  </ul>
                                </div>{" "}
                              </div>

                              <div
                                className="tab-pane"
                                id="checklist"
                                style={{ marginLeft: "30px" }}
                              >
                                <h4 className="mt-2"> Institutional </h4>
                                <div className="row">
                                  <form action="">
                                    <div className="col-md-4">
                                      <label for="country">
                                        Select Checklist <span>*</span>
                                      </label>
                                      <select
                                        name="country"
                                        id="country"
                                        className="slt_check"
                                        onClick={(e) => onChangeOption(e)}
                                      >
                                        <option disabled selected="selected">
                                          Select
                                        </option>
                                        <option value="LIST1">
                                          New Connection
                                        </option>
                                        <option value="LIST2">
                                          Name Mutation
                                        </option>
                                        <option value="LIST3">
                                          Categogy Change
                                        </option>
                                        <option value="LIST4">
                                          Permanent Disconnection & Security
                                          Refund
                                        </option>
                                        <option value="LIST5">
                                          Load Reduction/Load Augmentation
                                        </option>
                                        <option value="LIST6">
                                          Name & Address Correction
                                        </option>
                                        <option value="LIST7">
                                          Not in Use Declaration
                                        </option>
                                        <option value="LIST8">
                                          Shifting of Service / Meter
                                        </option>
                                      </select>
                                    </div>

                                    {show ? (
                                      <div className="list1">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/newConn_Domestic.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/NSC Dom Eng Hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

{showName ? (
                                      <div className="list2">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Name-Mutation.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/NTR Eng HN.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showCat ? (
                                      <div class="list3">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Category-Change.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/Category Hn  Eng.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

{showPer ? (
                                      <div className="list4">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Permanent-Disconnection-and-Security-Refund.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/PD SD NOC eng hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showLoad ? (
                                      <div className="list5">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Load-reduction.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/LALR engHn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showAddress ? (
                                      <div className="list6">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Name-and-address-correction.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/NAC Eng Hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

{showNot ? (
                                      <div className="list7">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/NotInUseDeclaration.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/Not in use Eng Hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}

                                    {showService ? (
                                      <div className="list8">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            <div className="personal_details">
                                              {/* <img
                                                src="https://iwebapps.noidapower.com:8032/images/Shifting_Of_Services.jpg"
                                                alt=""
                                              /> */}
                                              <embed
            src="https://iwebapps.noidapower.com:8032/DocumentChecklist/SFT eng Hn.pdf"
            type="application/pdf"
            width="100%"
            height="400px"
          />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default DocumentChecklist;
